@import '../../style/themes/index';
@import '../../style/mixins/index';

@statistic-prefix-cls: ~'@{ant-prefix}-statistic';

.@{statistic-prefix-cls} {
  .reset-component();

  &-title {
    margin-bottom: 4px;
    color: @text-color-secondary;
    font-size: @statistic-title-font-size;
  }

  &-content {
    color: @heading-color;
    font-size: @statistic-content-font-size;
    font-family: @statistic-font-family;

    &-value {
      &-decimal {
        font-size: @statistic-unit-font-size;
      }
    }

    &-prefix,
    &-suffix {
      display: inline-block;
    }

    &-prefix {
      margin-right: 4px;
    }

    &-suffix {
      margin-left: 4px;
      font-size: @statistic-unit-font-size;
    }
  }
}
