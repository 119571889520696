@import '../../style/themes/index';
@import '../../style/mixins/index';

@alert-prefix-cls: ~'@{ant-prefix}-alert';

@alert-message-color: @heading-color;
@alert-text-color: @text-color;
@alert-close-color: @text-color-secondary;
@alert-close-hover-color: @icon-color-hover;

@alert-with-description-icon-size: 24px;
@alert-with-description-no-icon-padding-vertical: @padding-md - 1px;
@alert-with-description-padding-vertical: @padding-md - 1px;
@alert-with-description-padding: @alert-with-description-padding-vertical 15px;

.@{alert-prefix-cls} {
  .reset-component();

  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: @border-radius-base;

  &-content {
    flex: 1;
    min-width: 0;
  }

  &-icon {
    margin-right: @margin-xs;
  }

  &-description {
    display: none;
    font-size: @font-size-base;
    line-height: @font-size-base + 8px;
  }

  &-success {
    background-color: @alert-success-bg-color;
    border: @border-width-base @border-style-base @alert-success-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-success-icon-color;
    }
  }

  &-info {
    background-color: @alert-info-bg-color;
    border: @border-width-base @border-style-base @alert-info-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-info-icon-color;
    }
  }

  &-warning {
    background-color: @alert-warning-bg-color;
    border: @border-width-base @border-style-base @alert-warning-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-warning-icon-color;
    }
  }

  &-error {
    background-color: @alert-error-bg-color;
    border: @border-width-base @border-style-base @alert-error-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-error-icon-color;
    }
  }

  &-close-icon {
    margin-left: @margin-xs;
    padding: 0;
    overflow: hidden;
    font-size: @font-size-sm;
    line-height: @font-size-sm;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    .@{iconfont-css-prefix}-close {
      color: @alert-close-color;
      transition: color 0.3s;
      &:hover {
        color: @alert-close-hover-color;
      }
    }
  }

  &-close-text {
    color: @alert-close-color;
    transition: color 0.3s;
    &:hover {
      color: @alert-close-hover-color;
    }
  }

  &-with-description {
    align-items: flex-start;
    padding: @alert-with-description-padding;
  }

  &-with-description&-no-icon {
    padding: @alert-with-description-no-icon-padding-vertical 15px;
  }

  &-with-description &-icon {
    margin-right: @alert-with-description-padding-vertical;
    font-size: @alert-with-description-icon-size;
  }

  &-with-description &-message {
    display: block;
    margin-bottom: 4px;
    color: @alert-message-color;
    font-size: @font-size-lg;
  }

  &-message {
    color: @alert-message-color;
  }

  &-with-description &-description {
    display: block;
  }

  &&-closing {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform-origin: 50% 0;
    transition: all 0.3s @ease-in-out-circ;
  }

  &-slide-up-leave {
    animation: antAlertSlideUpOut 0.3s @ease-in-out-circ;
    animation-fill-mode: both;
  }

  &-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
}

@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
