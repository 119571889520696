@import '../../style/themes/index';
@import '../../style/mixins/index';

@avatar-prefix-cls: ~'@{ant-prefix}-avatar';

.@{avatar-prefix-cls} {
  .reset-component();

  position: relative;
  display: inline-block;
  overflow: hidden;
  color: @avatar-color;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: @avatar-bg;

  &-image {
    background: transparent;
  }

  .avatar-size(@avatar-size-base, @avatar-font-size-base);

  &-lg {
    .avatar-size(@avatar-size-lg, @avatar-font-size-lg);
  }

  &-sm {
    .avatar-size(@avatar-size-sm, @avatar-font-size-sm);
  }

  &-square {
    border-radius: @avatar-border-radius;
  }

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.avatar-size(@size, @font-size) {
  width: @size;
  height: @size;
  line-height: @size;
  border-radius: 50%;

  &-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
  }

  &.@{avatar-prefix-cls}-icon {
    font-size: @font-size;
  }
}
