@import '../../button/style/mixin';

.@{calendar-prefix-cls}-picker-container {
  .reset-component();

  position: absolute;
  z-index: @zindex-picker;
  font-family: @font-family;

  &.slide-up-enter.slide-up-enter-active&-placement-topLeft,
  &.slide-up-enter.slide-up-enter-active&-placement-topRight,
  &.slide-up-appear.slide-up-appear-active&-placement-topLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-topRight {
    animation-name: antSlideDownIn;
  }

  &.slide-up-enter.slide-up-enter-active&-placement-bottomLeft,
  &.slide-up-enter.slide-up-enter-active&-placement-bottomRight,
  &.slide-up-appear.slide-up-appear-active&-placement-bottomLeft,
  &.slide-up-appear.slide-up-appear-active&-placement-bottomRight {
    animation-name: antSlideUpIn;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-topLeft,
  &.slide-up-leave.slide-up-leave-active&-placement-topRight {
    animation-name: antSlideDownOut;
  }

  &.slide-up-leave.slide-up-leave-active&-placement-bottomLeft,
  &.slide-up-leave.slide-up-leave-active&-placement-bottomRight {
    animation-name: antSlideUpOut;
  }
}

.@{calendar-prefix-cls}-picker {
  .reset-component();

  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;

  &-input {
    outline: none;

    &.@{ant-prefix}-input {
      line-height: @line-height-base;
    }
  }

  &-input.@{ant-prefix}-input-sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover &-input:not(.@{ant-prefix}-input-disabled) {
    border-color: @input-hover-border-color;
  }

  &:focus &-input:not(.@{ant-prefix}-input-disabled) {
    .active();
  }

  &-clear,
  &-icon {
    position: absolute;
    top: 50%;
    right: @control-padding-horizontal;
    z-index: 1;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    font-size: @font-size-sm;
    line-height: 14px;
    transition: all 0.3s;
    user-select: none;
  }

  &-clear {
    z-index: 2;
    color: @disabled-color;
    font-size: @font-size-base;
    background: @component-background;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    &:hover {
      color: @text-color-secondary;
    }
  }

  &:hover &-clear {
    opacity: 1;
    pointer-events: auto;
  }

  &-icon {
    display: inline-block;
    color: @disabled-color;
    font-size: @font-size-base;
    line-height: 1;
  }

  .@{ant-prefix}-input-disabled + &-icon {
    cursor: not-allowed;
  }

  &-small &-clear,
  &-small &-icon {
    right: @control-padding-horizontal-sm;
  }
}
